import React, { useState } from "react";
import querystring from "query-string";
import "./App.scss";
import axios from "axios";
import { Carousel, Button, Upload, Input } from "antd";
import ReactPlayer from "react-player/youtube";

const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const { TextArea } = Input

const App = () => {
  const [phonenumber, setPhonenumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneConsent, setPhoneConsent] = useState(true);
  const [termsConsent, setTermsConsent] = useState(true);
  const [filesList, setFilesList] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [thankyou, setThankyou] = useState(false);
  const countryCode = "+91";
  const [question, setQuestion] = useState("");

  const handleClickScroll = () => {
    const element = document.getElementById("upload-id");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const initializeState = () => {
    setPhonenumber("");
    setName("");
    setEmail("");
    setQuestion("");
    setPhoneConsent(true);
    setTermsConsent(true);
    setFilesList([]);
    setNameError(false);
    setPhoneError(false);
    setEmailError(false);
    setPhoneNumberValid(true);
    setEmailValid(true);
    setFormLoading(false);
  };

  const onNameChange = (e) => {
    setName(e.target.value);
    if (!e.target.value.length) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  const onNumberChange = (e) => {
    setPhonenumber(e.target.value);
    if (!e.target.value.length) {
      setPhoneError(true);
      setPhoneNumberValid(true);
    } else {
      setPhoneError(false);
      if (mobileRegex.test(e.target.value)) {
        setPhoneNumberValid(true);
      } else {
        setPhoneNumberValid(false);
      }
    }
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    if (!e.target.value.length) {
      setEmailError(true);
      setEmailValid(true);
    } else {
      setEmailError(false);
      if (emailRegex.test(e.target.value)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }
  };

  const onquestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const onUploadFileChange = (e) => {
    setFilesList([...filesList, e.file]);
  };

  const onFileRemove = (e) => {
    const newList = filesList.filter((file) => e.uid !== file.uid);
    setFilesList(newList);
  };

  const onFormSubmitClick = () => {
    if (!name.length) {
      setNameError(true);
      return null;
    }
    if (!phonenumber.length) {
      setPhoneError(true);
      return null;
    }

    if (!phoneNumberValid) {
      return null;
    }
    if (!emailValid) {
      return null;
    }

    const paramsObj = {
      name,
      email,
      phone: countryCode.concat(phonenumber),
      country: "India",
      language: "English",
      price: 18750,
      wa_text: "I agree to let Curia contact me via phone or email",
      tc_text: "I agree to Curia's Terms of Service and Privacy Policy",
      wa_consent: phoneConsent,
      tc_consent: termsConsent,
      phone_verified: true,
      text: question,
    };

    setFormLoading(true);

    const formData = new FormData();
    filesList.map((itm) => {
      formData.append("files", itm);
      return null;
    });

    axios.post(`https://api.oncocoin.ai/api/v1/website_so_doc/uploadWebsiteDoc`, paramsObj)
      .then(res => {
        if (filesList.length) {
          const uploadParams = {
            mongo_id: res.data.data,
            country: "India",
          };

          axios.post(`https://api.oncocoin.ai/api/v1/website_so_doc/uploadFilesS3?${querystring.stringify(uploadParams)}`, formData)
            .then(resp => {
              if (resp.status === 200) {
                setThankyou(true);
              }
            });
        } else {
          setThankyou(true);
        }
      });
  };

  return (
    <div className="page-second-opinion">
      <div className="page-second-opinion-header">
        <div className="left-header logo-curia">
          <div className="logo-curia-img" title="Curia" />
        </div>
        <div className="center-header header-text">
          <div className="header-text-in">
            Curia<sup>TM</sup> Second Opinion Service
          </div>
        </div>
        <div className="right-header header-buttons">
          <div className="header-button-btn">
            <div
              className="btn-book-now"
              role="presentation"
              onClick={handleClickScroll}
            >
              Book Now
            </div>
          </div>
        </div>
      </div>
      <div className="page-second-opinion-container">
        <div className="slider-show">
          <Carousel autoplay fade>
            <div className="slide-one carousel-slide">
              <div className="slide-img" />
              <div className="carousel-content">
                <div className="slide-main-text">
                  Beat your cancer with global experts.
                </div>
                <div className="slide-sub-text-top">
                  Save valuable time with a second opinion from the best
                  international oncology experts.
                </div>
                <div className="slide-sub-text">
                  We provide access to highly experienced tumor boards in
                  Germany who are certified
                  <br /> and part of the German Cancer Association and
                  specialized in your cancer indication
                </div>
                <div className="slide-buttons">
                  <div
                    className="slide-btn btn-book-now"
                    role="presentation"
                    onClick={handleClickScroll}
                  >
                    Book Now
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-two carousel-slide">
              <div className="slide-img carousel-slide" />
              <div className="carousel-content">
                <div className="slide-main-text">
                  Involve your treating oncologist.
                </div>
                <div className="slide-sub-text">
                  The second opinion report submission and follow-up call with
                  our experts will be <br /> done jointly with your oncologist.
                </div>

                <div className="slide-buttons">
                  <div
                    className="slide-btn btn-book-now"
                    role="presentation"
                    onClick={handleClickScroll}
                  >
                    Book Now
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-three carousel-slide">
              <div className="slide-img" />
              <div className="carousel-content">
                <div className="slide-main-text">Focus on your health</div>
                <div className="slide-sub-text">
                  Benefit from our easy and personalized service.
                </div>
                <div className="slide-sub-text">
                  Submit your medical records for free; our Curia experts are
                  getting in touch with you.
                </div>

                <div className="slide-buttons">
                  <div
                    className="slide-btn btn-book-now"
                    role="presentation"
                    onClick={handleClickScroll}
                  >
                    Book Now
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
        <div className="listen-ytube-page">
          <div className="listen-ytube-page-title">
            Listen to Mallika Sherawat, <br />
            on how Curia Second Opinion Service can help cancer patients
          </div>
          <div className="listen-ytube-page-emb-code">
            <ReactPlayer
              url="https://www.youtube-nocookie.com/embed/bO84w2Lxjww"
              light="https://curia.app/wp-content/uploads/2023/05/Mallika-sherawat-thumbnail.png"
              playing
            />
          </div>
          <div className="book-btn">
            <div
              className="btn-book-now"
              role="presentation"
              onClick={handleClickScroll}
            >
              Book Now
            </div>
          </div>
        </div>
        <div className="how-does-it-work-page">
          <div className="how-does-it-work-page-title for-desktop">
            How does it work?
          </div>
          <div className="how-does-it-work-page-layout">
            <div className="how-does-it-work-page-layout-left">
              <div className="for-mobile">How does it work?</div>
              <div className="easy-steps">
                <div className="easy-steps-title-big">3 easy steps </div>
                <div className="easy-steps-title-small">
                  to get a Second Opinion in just 8 days*
                </div>
                <div className="easy-steps-show">
                  <div className="easy-steps-show-step">
                    <div className="easy-steps-show-step-img step-img-one" />
                    <div className="easy-steps-show-step-text">
                      Upload your medical reports (doctor&prime;s letter, case
                      history, scans) and get started.
                    </div>
                    <div className="doc-info-text">
                      To get a comprehensive second opinion, please upload the
                      following documents: <br />
                      1. Doctor’s letter (with cancer type, age, date of
                      diagnosis) <br />
                      2. Biopsy reports <br />
                      3. Suggested treatment plan <br />
                      4. CT scan/PET scan/MRI report <br />
                    </div>
                  </div>
                  <div className="easy-steps-show-step">
                    <div className="easy-steps-show-step-img step-img-two" />
                    <div className="easy-steps-show-step-text">
                      Pay only after our Case Managers have validated the
                      medical documents and approved the case.
                    </div>
                  </div>
                  <div className="easy-steps-show-step">
                    <div className="easy-steps-show-step-img step-img-three" />
                    <div className="easy-steps-show-step-text">
                      Receive your personalized report within 8 days* from our
                      international oncology experts. Our Case Managers will be
                      available for any queries.
                    </div>
                    <div className="doc-info-text">
                      * After document acceptance & payment completion
                    </div>
                  </div>
                </div>
                <div className="easy-steps-show-below ">
                  <div className="easy-steps-title-small">
                    Customized Services for Your Unique Needs
                  </div>
                  <div className="easy-steps-show">
                    <div className="easy-steps-show-step">
                      <div className="easy-steps-show-step-text">
                        Guideline-based <br /> diagnosis confirmation
                      </div>
                      <div className="doc-info-text">
                        Our experts can confirm your
                        <br /> diagnosis based on your
                        <br /> submitted medical reports
                      </div>
                      {/* <div className="fees-text"> Fees: 40 Euros </div> */}
                    </div>
                    <div className="easy-steps-show-step">
                      <div className="easy-steps-show-step-text">
                        First opinion based <br />
                        on international guidelines
                      </div>
                      <div className="doc-info-text">
                        Fast feedback on suggested
                        <br /> treatment plans based on
                        <br /> guidelines of German Cancer
                        <br /> Association
                      </div>
                      {/* <div className="fees-text"> Fees: 80 Euros </div> */}
                    </div>
                    <div className="easy-steps-show-step">
                      <div className="easy-steps-show-step-text">
                        Second Opinion Service <br />
                        through experienced tumor
                        <br /> boards in Germany
                      </div>
                      <div className="doc-info-text">
                        Second Opinion provided after <br />
                        review of medical reports by 15-20
                        <br /> experts (who are a part of the <br />
                        German Cancer Association) in
                        <br /> multiple oncology disciplines
                      </div>
                      {/* <div className="fees-text"> Fees: 160 Euros </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="upload-id" className="how-does-it-work-page-layout-right">
              {formLoading ? <div className="overlay-sec" /> : null}
              {thankyou ? (
                <div className="thankyou-page">
                  <div className="thankyou-page-content">
                    <div className="thankyou-page-img" />
                    <div className="thankyou-page-content">
                      <div className="thankyou-page-content-text">
                        Thank you!
                      </div>
                      <div className="thankyou-page-content-text-small">
                        We will contact you soon.
                      </div>
                    </div>
                    <div className="thankyou-page-done">
                      <Button
                        className="btn-thankyou-page-done"
                        onClick={() => {
                          setThankyou(false);
                          initializeState();
                        }}
                      >
                        Done
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
              {!thankyou ? (
                <div className="upload-form-sec">
                  <div className="title-sec">
                    <div className="form-sec-title">
                      Submit your medical records for free and our Curia Medical
                      Experts will get in touch with you.
                    </div>
                  </div>
                  <div className="form-wrap">
                    <div className="form-input-itm name">
                      <input
                        placeholder="Name*"
                        value={name}
                        onChange={onNameChange}
                        type="text"
                        maxLength="255"
                      />
                      {nameError ? (
                        <div className="error-message">
                          Name is a required field
                        </div>
                      ) : null}
                    </div>
                    <div className="form-input-itm mobilenumber">
                      <input
                        value="+91"
                        className="country-code"
                        onChange={() => {
                          return;
                        }}
                      />
                      <input
                        placeholder="Mobile Number*"
                        value={phonenumber}
                        onChange={onNumberChange}
                        type="tel"
                        className="mobile-no"
                      />
                      {phoneError ? (
                        <div className="error-message">
                          Mobile number is a required field
                        </div>
                      ) : null}
                      {!phoneNumberValid ? (
                        <div className="error-message">
                          Please enter a valid phone number
                        </div>
                      ) : null}
                    </div>
                    <div className="form-input-itm email">
                      <input
                        placeholder="Email"
                        value={email}
                        onChange={onEmailChange}
                        type="email"
                      />
                      {emailError ? (
                        <div className="error-message">
                          Email is a required field
                        </div>
                      ) : null}
                      {!emailValid ? (
                        <div className="error-message">
                          Please enter a valid email address
                        </div>
                      ) : null}
                    </div>

                    <div className="form-input-itm question">
                      <div className="question-title">
                        Share any questions that you may have (optional)
                      </div>
                      <TextArea
                        placeholder="Type your question here"
                        value={question}
                        onChange={onquestionChange}
                        cols="50"
                        style={{ resize: 'none' }}
                        maxLength={1056}
                      />
                    </div>
                  </div>
                  <div className="upload-wrap">
                    <div className="upload-title">
                      Upload reports (optional)
                    </div>
                    <Upload
                      accept="image/*,.pdf"
                      fileList={filesList}
                      customRequest={onUploadFileChange}
                      onRemove={onFileRemove}
                    >
                      <div className="upload-input-wrap">
                        <span className="upload-input-text">
                          Choose files to upload
                        </span>
                        <Button>Browse files</Button>
                      </div>
                    </Upload>
                    <div className="upload-info">
                      <div className="upload-info-text">
                        File type supported: .pdf, .jpeg, .jpg, . png
                      </div>
                      <div style={{ paddingTop: "10px" }}>
                        <div>
                          To get a comprehensive second opinion, please upload
                          the following documents:{" "}
                        </div>
                        <div>
                          1. Doctor’s letter (with cancer type, age, date of
                          diagnosis), 2. Biopsy reports, 3. Suggested treatment
                          plan, 4. CT scan/PET scan/MRI report
                        </div>
                      </div>
                    </div>
                    <div className="consent-wrap">
                      <div className="consent-itm phonenumber">
                        <input
                          type="checkbox"
                          onChange={() => setPhoneConsent(!phoneConsent)}
                          checked={phoneConsent}
                        />
                        <span className="consent-text">
                          I agree to let Curia contact me via phone or email
                        </span>
                      </div>
                      <div className="consent-itm termnconditions">
                        <input
                          type="checkbox"
                          onChange={() => setTermsConsent(!termsConsent)}
                          checked={termsConsent}
                        />
                        <span className="consent-text">
                          I agree to Curia's
                          <a
                            className="footer-text-link"
                            href="https://curia.app/terms-of-use-for-second-opinion/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms of Service
                          </a>
                          and
                          <a
                            className="footer-text-link"
                            href="https://curia.app/privacy-policy/web/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="upload-form-buttons-wrap">
                      <Button
                        onClick={onFormSubmitClick}
                        disabled={!phoneConsent || !termsConsent}
                      >
                        Submit for free
                      </Button>
                    </div>
                  </div>
                  <div id="recaptcha-container" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="why-curia-page">
          <div className="why-curia-page-title for-desktop">Why Curia?</div>
          <div className="why-curia-page-main">
            <div className="why-curia-page-layout">
              <div className="easy-steps easy-steps-combine">
                <div className="for-mobile">Why Curia?</div>
                <div className="easy-steps-title-small">
                  Curia brings you the best service at your doorstep
                </div>
                <div className="card-text-why-curia">
                   <div className="card-text-why-curia-text"><span className="quote-sec" />We provide access to a network of over <span className="bold-text">180 tumor centers</span> certified by the German Cancer Society, including 18 university hospitals.
Due to the high certification standard in Germany, the cooperating clinics have a high number of cases for the certified cancer indications, so that experience leads to better results in cancer therapy.
</div>
                    <div className="card-text-why-curia-text">In the case of rare cancers, the patient's chance of recovery can be <span className="bold-text">improved by 20%</span>. In addition, the certified clinics have access to the latest scientific findings and can therefore also recommend new and innovative therapy methods if these offer better chances of recovery in individual cases.</div>

                </div>
                <div className="easy-steps-show">
                  <div className="easy-steps-show-step">
                    <div className="easy-steps-show-step-img step-img-four" />
                    <div className="easy-steps-show-step-text">
                      Pay only after verification
                      <br /> of your documents.
                    </div>
                  </div>
                  <div className="easy-steps-show-step">
                    <div className="easy-steps-show-step-img step-img-five" />
                    <div className="easy-steps-show-step-text">
                      Avoid traveling and focus <br />
                      on your recovery.
                    </div>
                  </div>
                  <div className="easy-steps-show-step">
                    <div className="easy-steps-show-step-img step-img-six" />
                    <div className="easy-steps-show-step-text">
                      Get your personalized <br />
                      report within 8 days*.
                    </div>
                    <div className="doc-info-text">
                      * After document acceptance
                      <br /> and payment completion
                    </div>
                  </div>
                </div>
              </div>
              <div className="easy-steps easy-steps-combine">
                <div className="easy-steps-title-small">
                  3 reasons to choose Curia
                </div>
                <div className="easy-steps-show">
                  <div className="easy-steps-show-step">
                    <div className="easy-steps-show-step-img step-img-seven" />
                    <div className="easy-steps-show-step-text">
                      Include your treating
                      <br /> oncologist in the process.
                    </div>
                  </div>
                  <div className="easy-steps-show-step">
                    <div className="easy-steps-show-step-img step-img-eight" />
                    <div className="easy-steps-show-step-text">
                      Receive personalized <br />
                      recommendations from
                      <br /> top global experts.
                    </div>
                  </div>
                  <div className="easy-steps-show-step">
                    <div className="easy-steps-show-step-img step-img-nine" />
                    <div className="easy-steps-show-step-text">
                      We offer a follow-up call with <br />
                      our Case Managers and your
                      <br /> treating oncologist upon
                      <br /> request.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-page">
          <div className="testimonial-page-title for-desktop">Testimonials</div>
          <div className="speakers-and-testimonials">
            <div className="speakers-and-testimonials-y">
              <div className="for-mobile">Testimonials</div>
              <div className="speakers-and-testimonials-y-content">
                <div className="speakers-and-testimonials-y-content-title">
                  {" "}
                  Listen to Mrs. Poonam Nanda Founder of
                  <br /> YouWeCan foundation, on how Second opinion
                  <br /> can help cancer patients
                </div>
                <div className="speakers-and-testimonials-y-content-ytube">
                  <ReactPlayer
                    url="https://www.youtube.com/shorts/xKGSngGZC1I"
                    light="https://curia.app/wp-content/uploads/2023/06/Poonam-Nanda.png"
                    playing
                  />
                </div>
              </div>
            </div>
            <div className="testimonial-page-content">
              <Carousel autoplay ease>
                <div className="testimonial-page-content-in">
                  <div className="testimonial-page-content-slide">
                    <div className="testimonial-slide">
                      <div className="testimonial-slide-content">
                        I really liked the format of the report. It is very
                        comprehensive and detailed. The service was of value to
                        me and I would definitely recommend it to cancer
                        patients. After the second opinion I had to undergo some
                        tests that the Curia oncologists suggested and now I am
                        on a treatment. The doctor saw that there was a mistake
                        in the lab results!
                      </div>
                      <div className="patient-name">
                        <div className="patient-name-content">
                          - 26 yr Old, Male Patient <br />
                          <span
                            style={{ fontSize: "12px", fontStyle: "italic" }}
                          >
                            Diagnosed with Chronic Myeloid Leukemia{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="testimonial-page-content-in">
                  <div className="testimonial-page-content-slide">
                    <div className="testimonial-slide">
                      <div className="testimonial-slide-content">
                        The data upload/sharing was easy. Your service was very
                        convenient and helpful, thanks for supporting!
                        <div />
                      </div>
                      <div className="patient-name">
                        <div
                          className="patient-name-content"
                          style={{ paddingTop: "13px" }}
                        >
                          - 48 yr Old, Female Patient <br />
                          <span
                            style={{ fontSize: "12px", fontStyle: "italic" }}
                          >
                            Diagnosed with Locally Invasive Urothelial Bladder
                            Cancer
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
        <div className="more-info-curia-page">
          <div className="more-info-curia-page-content">
            <div className="more-info-curia-page-content-title">
              For more information, please download the Curia app
            </div>
            <div className="more-info-curia-page-content-apps">
              <div className="more-info-curia-page-content-apps-img apple-link-img">
                <a
                  href="https://apps.apple.com/us/app/id1489153159"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="apple-link-img-icon" />
                </a>
              </div>
              <div className="more-info-curia-page-content-apps-img android-link-img">
                <a
                  href="https://play.google.com/store/apps/details?id=com.innoplexus.patientapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="android-link-img-icon" />
                </a>
              </div>
            </div>
            <div className="more-info-curia-page-footer-text">
              *The report is issued by the Tumor Board/Physician who is
              approbated internationally. For the avoidance of doubt, Innoplexus
              AG is not providing any medical services.
            </div>
          </div>
        </div>
      </div>
      <div className="page-second-opinion-footer">
        <div className="page-second-opinion-footer-content">
          <div className="page-second-opinion-footer-content-logo">
            <div className="page-second-opinion-footer-content-logo-img" />
          </div>
          <div className="page-second-opinion-footer-content-followus">
            <div className="page-second-opinion-footer-content-followus-title">
              Follow us
            </div>
            <div className="page-second-opinion-footer-content-followus-link">
              <a
                href="https://www.instagram.com/curia.for.patients/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="page-second-opinion-footer-content-followus link-insta" />
              </a>
              <a
                href="https://www.facebook.com/curiaforpatients"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="page-second-opinion-footer-content-followus link-fb" />
              </a>
              <a
                href="https://discord.com/invite/BvJAZJfxU5"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="page-second-opinion-footer-content-followus link-discord" />
              </a>
            </div>
          </div>
          <div className="footer-menu-sec">
            <div className="footer-menu-sec-in">
              <div className="footer-menu-sec-in-links">
                <a
                  href="https://curia.app/about"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About us
                </a>
              </div>
              <div className="footer-menu-sec-in-links">
                <a
                  href="https://curia.app/curia-feature"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Curia Features
                </a>
              </div>
              <div className="footer-menu-sec-in-links">
                <a
                  href="https://curia.app/blog"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </div>
              <div className="footer-menu-sec-in-links">
                <a
                  href="https://curia.app/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact
                </a>
              </div>
              <div className="footer-menu-sec-in-links">
                <a
                  href="https://amrit.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Amrit
                </a>
              </div>
            </div>
          </div>
          <div className="footer-text">
            <div className="footer-text-copyright">
              Copyright © 2023 - Curia<sup>TM</sup>
            </div>
            <a
              className="footer-text-link"
              href="https://curia.app/privacy-policy/web/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <a
              className="footer-text-link"
              href="https://curia.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Impressum
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
